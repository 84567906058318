import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { updateJobAppliedDialog } from "../../../redux/actions/dialog_action";
import {
  ArtImage,
  BannerArtContainer,
  BannerContainer,
  BannerSubtitle,
  BannerTextContainer,
  BannerTitle,
  BottomContainer,
  BoxContainer,
  ButtonStyled,
  CheckIcon,
  CloseButton,
  CloseIcon,
  ContentContainer,
  ContentText,
  DialogContainer,
  DownloadButtonsContainer,
  DownloadChip,
  DownloadIcon,
  LinkStyled,
  QrContainer,
  QrImage,
  TextStyled,
  TopContainer,
} from "./styles";

function JobAppliedDialog() {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const dispatch = useDispatch();

  const storeList = [
    {
      name: "App Store",
      iconUrl: "/images/hiredly/apple-store-logo.webp",
      alt: "apple app store",
      downloadSlug: "https://itunes.apple.com/us/app/wobb/id981491669?mt=8",
    },
    {
      name: "Play Store",
      iconUrl: "/images/hiredly/play-store-logo.webp",
      alt: "google play store",
      downloadSlug:
        "https://play.google.com/store/apps/details?id=com.wobb.hunted&hl=en",
    },
    {
      name: "AppGallery",
      iconUrl: "/images/hiredly/appgallery-logo.webp",
      alt: "huawei app gallery",
      downloadSlug:
        "https://appgallery.huawei.com/#/app/C101027941?channelId=homepage-footer&id=8c375cc9194d469488f3f163394cfbd5&s=EDFF426DF95D1FCBCCE03918FB64DFF9C7197D34B481DE8A2BE8654FDF391EFB&detailType=0&v=",
    },
  ];

  const imageArtUrl = isMobile
    ? "/images/hiredly/apply-mobile-app-ad-mobile.webp"
    : "/images/hiredly/apply-mobile-app-ad-desktop.webp";

  const showJobAppliedDialog = useSelector(
    (state) => state.dialog.showJobAppliedDialog
  );

  function handleClose() {
    dispatch(updateJobAppliedDialog(false));
  }

  return (
    <DialogContainer open={showJobAppliedDialog} onClose={handleClose}>
      <TopContainer>
        <BoxContainer gap={"0.5rem"} alignItems={"center"}>
          <CheckIcon />
          <TextStyled
            fontSize={isMobile ? "0.875rem" : "1.25rem"}
            fontWeight={"700"}
            lineHeight={"24px"}
          >
            Thank you for applying!
          </TextStyled>
        </BoxContainer>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </TopContainer>

      <ContentContainer>
        <BoxContainer
          flexDirection={"column"}
          padding={!isMobile && "0.5rem 0"}
        >
          <ContentText>
            Your application will be submitted to the employer in 30 minutes.
            {!isMobile && <br />} <b>Review or undo</b> your application in your{" "}
            <LinkStyled
              href={"/profile#job-application-history"}
              textDecoration={"underline"}
            >
              Job Application Status
            </LinkStyled>
            .
          </ContentText>
        </BoxContainer>

        <BannerContainer>
          <BannerTextContainer>
            <BoxContainer>
              <BoxContainer flexDirection={"column"} gap={"0.75rem"}>
                <BannerTitle>
                  If you’re shortlisted, employers may message you directly on
                  our app!
                </BannerTitle>
                <BannerSubtitle>
                  Don’t miss a message, download the Hiredly App here!
                </BannerSubtitle>
              </BoxContainer>
              <QrContainer>
                <QrImage
                  src={
                    config.assetDomain +
                    "/images/hiredly/mobile-app-qr-download.webp"
                  }
                />
              </QrContainer>
            </BoxContainer>
            {isMobile && (
              <DownloadButtonsContainer>
                {storeList.map((store, index) => {
                  return <MobileAppDownloadChip store={store} key={index} />;
                })}
              </DownloadButtonsContainer>
            )}
          </BannerTextContainer>
          <BannerArtContainer>
            <ArtImage src={config.assetDomain + imageArtUrl} />
          </BannerArtContainer>
        </BannerContainer>
      </ContentContainer>

      {!isMobile && (
        <BottomContainer>
          <ButtonStyled onClick={handleClose}>Continue</ButtonStyled>
        </BottomContainer>
      )}
    </DialogContainer>
  );
}

function MobileAppDownloadChip(props) {
  const { store } = props;
  return (
    <LinkStyled
      href={store.downloadSlug}
      rel="noopener"
      target="_blank"
      textDecoration={"none"}
    >
      <DownloadChip>
        <DownloadIcon
          src={config.assetDomain + store.iconUrl}
          alt={store.alt}
        />
        <BoxContainer flexDirection={"column"}>
          <TextStyled
            fontSize={"7.325px"}
            fontWeight={300}
            letterSpacing={"0.073px"}
          >
            Get app -
          </TextStyled>
          <TextStyled
            fontSize={"8.371px"}
            fontWeight={600}
            letterSpacing={"0.084px"}
          >
            {store.name}
          </TextStyled>
        </BoxContainer>
      </DownloadChip>
    </LinkStyled>
  );
}

export default JobAppliedDialog;
