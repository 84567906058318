import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    border: "1.5px solid " + Color.black,
    borderRadius: "1.5rem",
    overflow: "clip",

    maxWidth: "804px",
    width: "100%",
  },
});

export const TopContainer = styling("div")({
  display: "flex",
  justifyContent: "space-between",

  borderBottom: "1px solid" + Color.grey,

  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "1rem 1rem 0.5rem",
  },
});

export const ContentContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",

  height: "100%",
  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "1rem",
  },
});

export const BottomContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderTop: "1px solid" + Color.grey,

  padding: "1rem 0",
});

export const BannerContainer = styling("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",

  borderRadius: "0.5rem",

  height: "199px",
  width: "100%",
  overflow: "clip",

  "@media (max-width: 640px)": {
    flexDirection: "column-reverse",
    border: "1px solid" + Color.grey,
    height: "354px",
  },
});

export const BannerTextContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "1.125rem",

  background: "linear-gradient(100deg, #512ACC 0.18%, #7556DC 41.6%)",

  height: "100%",
  width: "56%",
  padding: "2rem 1.5rem",

  "@media (max-width: 640px)": {
    gap: "0.5rem",
    height: "58%",
    width: "100%",
    padding: "0.875rem 1.5rem 1rem",
  },
});

export const BannerArtContainer = styling("div")({
  backgroundColor: "#EFEFFD",
  width: "44%",

  "@media (max-width: 640px)": {
    height: "42%",
    width: "100%",
  },
});

export const QrContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: "0.75rem",
  border: "1px solid" + Color.black,
  backgroundColor: Color.white,

  height: "112px",
  width: "112px",
  padding: "0.75rem",

  "@media (max-width: 640px)": {
    borderRadius: "0.5rem",
    height: "96px",
    width: "96px",
    padding: "0.5rem",
  },
});

export const DownloadButtonsContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.25rem",
}));

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const ContentText = styling(Typography)({
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const BannerTitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1.25rem",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "21px",
  },
});

export const BannerSubtitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.625rem",
    lineHeight: "15px",
  },
});

export const TextStyled = styling(Typography)({});

export const LinkStyled = styling("a")((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "700",
  textDecoration: props.textDecoration,

  "&:hover": {
    cursor: "pointer",
  },
}));

export const CloseButton = styling(ButtonBase)({});

export const ButtonStyled = styling(ButtonBase)({
  border: "1px solid" + Color.black,
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "500",
  letterSpacing: "0.074px",

  height: "42px",
  width: "200px",
});

export const QrImage = styling("img")({
  height: "100px",
  width: "100px",

  "@media (max-width: 640px)": {
    height: "80px",
    width: "80px",
  },
});

export const ArtImage = styling("img")({
  position: "absolute",
  top: -22,
  right: -10,
  objectFit: "contain",

  height: "140%",
  width: "auto",

  "@media (max-width: 640px)": {
    top: -28,
    right: -6,
    height: "60%",
  },
});

export const DownloadChip = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  color: Color.black,
  backgroundColor: Color.white,
  border: "0.5px solid" + Color.black,
  borderRadius: "0.375rem",

  width: "5.25rem",
  padding: "0.26rem 0.4575rem",
  userSelect: "none",
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.grey,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const CheckIcon = styling(CheckCircleIcon)({
  color: Color.buttonGreen,
  height: "32px",
  width: "32px",

  "@media (max-width: 640px)": {
    height: "24px",
    width: "24px",
  },
});

export const DownloadIcon = styling("img")({
  backgroundColor: Color.white,
  height: "18px",
  width: "auto",
});
